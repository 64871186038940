<template>
  <div>
    <div v-if="isShow" class="main_page">
      <i class="el-icon-circle-check" style="font-size: 50px; margin-top: 50px; color: #1890ff; margin-bottom: 25px; " />
      <h3>成功生成采购计划单</h3>
      <div>单号：
        <span v-for="item in successData" :key="item.purchasePlanCode">
          <router-link
            :to="{ path: '/purchase-plan-management/purchasplan/vieworder', query: { id: item.id , disabled: true}}"
            style="color:#1890ff;"
          >{{ item.purchasePlanCode }}、</router-link>
          &nbsp;&nbsp;
        </span>
      </div>
      <div class="mt-15" style="margin-top: 15px">
        <el-button @click="$router.go(-1)">完成</el-button>
      </div>
    </div>
    <div v-else>
      <div>
        <el-steps :active="active" align-center>
          <el-step title="确认下单建议" />
          <el-step title="确认下单颜色" />
          <el-step title="生成采购计划单" />
        </el-steps>
        <main class="upload-order-content">
          <section v-show="active === 0" class="import-orders">
            <el-form ref="Orderform" :model="Orderform" label-width="80px">
              <el-row>
                <el-col :span="5">
                  <el-form-item label="Style">
                    <el-select v-model="Orderform.style" clearable filterable @change="e=>handleStyleChange(e,Orderform)">
                      <el-option
                        v-for="item in styleOptions"
                        :key="item.id"
                        :label="item.styleName"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item label="Color">
                    <el-select v-model="Orderform.color" clearable filterable id:placeholder="$t('page.selectPlaceholder')">
                      <el-option
                        v-for="item in colorOptions"
                        :key="item.id"
                        :label="item.colorName"
                        :value="item.colorName"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item label="Size">
                    <el-select v-model="Orderform.size" clearable filterable id:placeholder="$t('page.selectPlaceholder')">
                      <el-option
                        v-for="item in sizeOptions"
                        :key="item.id"
                        :label="item.size"
                        :value="item.size"
                      />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="5">
                  <el-form-item label="Sku">
                    <el-input v-model="Orderform.sku" :placeholder="$t('page.inputPlaceholder')" />
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <el-button type="primary" class="ml-3" @click="queryClick()">{{ $t('page.search') }}</el-button>
                  <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <span>批量确认：
                    <el-button type="text" @click="handleExport">导出</el-button>
                    <el-button type="text" @click="importDialogVisible = true">导入</el-button>
                  </span>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="取整箱方式" prop="resource" required label-width="120px">
                    <el-radio-group v-model="Orderform.resource" @change="changeResource">
                      <el-radio label="1">向上取整箱</el-radio>
                      <el-radio label="2">向下取整箱</el-radio>
                      <el-radio label="3">不取整箱数</el-radio>
                    </el-radio-group>
                  </el-form-item>

                </el-col>
              </el-row>
            </el-form>
            <el-divider />
            <vxe-table
              ref="OrderTable"
              border
              :data="OrderShowTableData"
              :show-header-overflow="false"
              max-height="500px"
              auto-line-height
              align="center"
              :scroll-y="{enabled: true, gt: 0}"
              :edit-rules="OrdervalidRules"
              :edit-config="{trigger: 'click', mode: 'cell'}"
              :row-config="{ height: 80 }"
              show-footer
              :footer-method="footerMethod"
            >
              <vxe-table-column field="stockChannelName" title="备货渠道" min-width="120" />
              <vxe-table-column field="styleName" title="Style" min-width="100" />
              <vxe-table-column field="color" title="Color" min-width="100" />
              <vxe-table-column field="size" title="Size" min-width="80" />
              <vxe-table-column field="sku" title="Sku" min-width="80" />
              <vxe-column field="positionName" title="定位" min-width="120" />
              <vxe-column field="negativeCashFlag" title="是否负现金流" min-width="120" />
              <vxe-table-column field="turnOver" title="翻单TurnOver（天）" min-width="170" />
              <vxe-table-column field="cartonNumber" title="装箱数" width="80" />
              <vxe-table-column field="totalNetShippingDemand" title="翻单需求" width="100" />
              <vxe-table-column field="demandNumber" title="实际下单数量" width="140" :edit-render="{}" fixed="right">
                <template #edit="{ row }">
                  <el-input-number v-model="row.demandNumber" :min="0" :max="999999" :precision="0" :controls="false" placeholder="请输入自然数" style="width: 100%" @blur="e=>handleBlur(e,row)" />
                </template>
              </vxe-table-column>
            </vxe-table>
          </section>
          <section v-show="active === 1" class="import-orders">
            <el-form ref="Deliveryform" :model="Deliveryform" label-width="80px" style="display: flex;">
              <div class="el-lt" style="width:85%;">
                <el-row :span="24">
                  <el-col :span="6">
                    <el-form-item label="Style" :class="$i18n.locale">
                      <el-select v-model="Deliveryform.style" clearable filterable @change="e=>handleStyleChange(e,Deliveryform)">
                        <el-option
                          v-for="item in styleOptions"
                          :key="item.id"
                          :label="item.styleName"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="Color" :class="$i18n.locale">
                      <el-select v-model="Deliveryform.color" clearable filterable id:placeholder="$t('page.selectPlaceholder')">
                        <el-option
                          v-for="item in colorOptions"
                          :key="item.id"
                          :label="item.colorName"
                          :value="item.colorName"
                        />
                      </el-select>
                    </el-form-item>
                  </el-col>

                </el-row>
              </div>
              <el-row class="el-rt" style="width:25%;">
                <el-col class="row-center">
                  <el-button type="primary" @click="queryClick(true)">{{ $t('page.search') }}</el-button>
                  <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
                </el-col>
              </el-row>
            </el-form>
            <el-divider />
            <vxe-table
              border
              :span-method="rowspanMethod"
              :data="DeliveryShowTableData"
              :show-header-overflow="false"
              max-height="600px"
              align="center"
              :scroll-y="{enabled: false}"
              :edit-config="{trigger: 'click', mode: 'cell'}"
              :row-config="{ height: 80 }"
              show-footer
              auto-resize
              :footer-method="footerMethod"
            >
              <vxe-column field="stockChannelName" title="备货渠道" min-width="120" />
              <vxe-column field="styleName" title="Style" min-width="120">
                <template #default="{ row }">
                  <div>
                    <div style="margin-bottom:-4px">{{ row.styleName }}</div>
                    <div>[合计：{{ row.totalCount }}]</div>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="color" title="Color" min-width="120" />
              <vxe-column field="demandNumber" title="实际下单数量" min-width="150" />
              <vxe-column field="color" title="操作" width="120">
                <template #default="{ row,rowIndex }">
                  <el-button type="text" @click="handleCancel(row,rowIndex)">取消下单</el-button>
                </template>
              </vxe-column>
            </vxe-table>
          </section>
          <section v-show="active === 2" class="data-review">
            <vxe-table
              ref="PlanTable"
              border
              :data="PlanTableData"
              :show-header-overflow="false"
              max-height="500px"
              align="center"
              :edit-config="{trigger: 'click', mode: 'cell'}"
              :row-config="{ height: 80 }"
              :loading="loading"
            >
              <vxe-table-column field="purchasePlanCode" title="采购计划单号" width="180" />
              <vxe-table-column field="type" title="计划类型" min-width="120">
                <template #default="{row}">
                  {{ row.type === 0 ? '普通计划' : '' }}
                </template>
              </vxe-table-column>
              <vxe-table-column field="style" title="Style" width="120" />
              <vxe-table-column field="ltDays" title="LT" width="120" />
              <vxe-table-column field="stockChannelName" title="备货渠道" width="120" />
              <vxe-table-column field="platformCode" title="平台/站点/店铺" width="140">
                <template #default="{ row }">
                  <span>{{ `${row.platformCode}/${row.siteCode}/${row.shopCode}` }}</span>
                </template>
              </vxe-table-column>
              <vxe-table-column field="totalPairs" title="数量" width="80" />
              <vxe-table-column field="expectDeliveryDate" title="期望交货日期" width="180" :edit-render="{}">
                <template #edit="{ row }">
                  <vxe-input
                    v-model="row.expectDeliveryDate"
                    type="date"
                    placeholder="请选择日期"
                    transfer
                  />
                </template>
              </vxe-table-column>
              <vxe-table-column field="remark" title="备注" width="140" :edit-render="{}">
                <template #edit="{ row }">
                  <el-input v-model="row.remark" type="text" maxlength="256" placeholder="输入内容" clearable />
                </template>
              </vxe-table-column>
            </vxe-table>
          </section>
        </main>
        <span class="footer">
          <el-button v-show="active === 0" @click="goBack">返回</el-button>
          <el-button v-show="[1,2].includes(active)" :loading="loading" @click="handleToReview('previous')">上一步</el-button>
          <el-button
            v-show="[0,1,2].includes(active)"
            type="primary"
            :loading="loading"
            @click="handleToReview('next')"
          >下一步</el-button>
        </span>
        <!-- 导入文件弹窗 -->
        <Import v-model="importDialogVisible" :sku-names="skuNames" :stock-channel-name="stockChannelName" @importTableData="getimportTableData" />

      </div>
    </div>
  </div>
</template>
<script>
import { querySizeList, queryColorList, groupPurchasePlan, generatePurchasePlan, produceNext } from '@/api/scm-api'
import { produceSkuValidate } from '@/api/supply'
import Import from '../components/Import'
import { mapGetters } from 'vuex'
import { trackSiteSearch, handleBreadTitle, trackExportEvent, trackImportEvent } from '@/utils/monitor'
import { handleMonitorParams } from '@/utils/index'
import { deepClone } from '@/utils'
import { omit } from 'lodash'
export default {
  components: { Import },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mergeCells: [],
      isShow: false,
      file: '',
      active: 0,
      loading: false,
      Orderform: {
        color: '',
        size: '',
        sku: '',
        style: '',
        resource: '3'
      },
      Deliveryform: {
        color: '',
        size: ''
      },
      styleOptions: [],
      colorOptions: [],
      sizeOptions: [],
      OrderTable: [],
      OrderShowTableData: [],
      OrderTableData: [],
      OrdervalidRules: {
        demandNumber: [{ required: true, message: this.$t('page.inputPlaceholder') }]
      },
      skuNames: '',
      stockChannelName: '',
      importDialogVisible: false,
      exportLoading: false,
      DeliveryTableData: [],
      DeliveryShowTableData: [],

      PlanTable: [],
      PlanTableData: [],
      successData: [],
      getLoading: false
    }
  },
  computed: {
    queryOrderParams() {
      return Object.assign({}, this.Orderform)
    },
    queryDeliveryParams() {
      return Object.assign({}, this.Deliveryform)
    },
    ...mapGetters([
      'flipOrderData'
    ]),
    OrderShowTableDatas() {
      return this.OrderTableData.filter(v => v.color == this.queryOrderParams.color)
    }
  },
  watch: {
    active(val) {
      if (val !== 0) {
        this.Orderform = this.$options.data.call(this).Orderform
      }
      if (val !== 1) {
        this.Deliveryform = this.$options.data.call(this).Deliveryform
      }

      this.colorOptions = []
      this.sizeOptions = []
    }
  },
  mounted() {
    this.getList()
  },
  methods: {

    handleCancel(row, index) {
      this.$confirm('该操作将取消所选颜色的下单数量，无法撤销，是否继续？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const { styleName, color } = row
          const idx = this.DeliveryTableData.findIndex(v => v.styleName === styleName && v.color === color)
          // 重新计算汇总
          this.DeliveryShowTableData.splice(index, 1)
          this.DeliveryTableData.splice(idx, 1)

          this.DeliveryShowTableData = this.handleCount(this.DeliveryShowTableData)
          this.DeliveryTableData = this.handleCount(this.DeliveryTableData)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消操作'
          })
        })
    },
    rowspanMethod({ row, _rowIndex, column, visibleData }) {
      const fields = ['styleName', 'stockChannelName']
      const cellValue = row[column.property]
      if (cellValue && fields.includes(column.property)) {
        const prevRow = visibleData[_rowIndex - 1]
        let nextRow = visibleData[_rowIndex + 1]
        if (prevRow && prevRow[column.property] === cellValue) {
          return { rowspan: 0, colspan: 0 }
        } else {
          let countRowspan = 1
          while (nextRow && nextRow[column.property] === cellValue) {
            nextRow = visibleData[++countRowspan + _rowIndex]
          }
          if (countRowspan > 1) {
            return { rowspan: countRowspan, colspan: 1 }
          }
        }
      }
    },
    handleStyleChange(val, form) {
      form.color = ''
      form.size = ''
      if (!val) return
      this._queryColorList(val)
      this._querySizeList(val)
    },
    handleBlur(e, row) {
      row.defaultDemandNumberByUser = e?.target?.value
      this.OrderTableData.map(v => {
        if (v.sku === row.sku) {
          v.defaultDemandNumberByUser = e?.target?.value
          v.demandNumber = e?.target?.value
        }
      })
    },
    changeResource(value) {
      switch (value) {
        case '1':
          this.OrderShowTableData.map(v => {
            const { demandNumber = 0, cartonNumber = 0 } = v
            const num = cartonNumber * Math.ceil(demandNumber / cartonNumber)
            Object.assign(v, { demandNumber: num })
          })
          this.OrderTableData.map(v => {
            const { demandNumber = 0, cartonNumber = 0 } = v
            const num = cartonNumber * Math.ceil(demandNumber / cartonNumber)
            Object.assign(v, { demandNumber: num })
          })
          break
        case '2':
          this.OrderShowTableData.map(v => {
            const { demandNumber = 0, cartonNumber = 0 } = v
            const num = cartonNumber * Math.floor(demandNumber / cartonNumber)
            Object.assign(v, { demandNumber: num || demandNumber })
          })
          this.OrderTableData.map(v => {
            const { demandNumber = 0, cartonNumber = 0 } = v
            const num = cartonNumber * Math.floor(demandNumber / cartonNumber)
            Object.assign(v, { demandNumber: num || demandNumber })
          })
          break
        case '3':
          this.OrderShowTableData.map(v => {
            Object.assign(v, { demandNumber: Number(v.defaultDemandNumberByUser || v.totalNetShippingDemand) })
          })
          this.OrderTableData.map(v => {
            Object.assign(v, { demandNumber: Number(v.defaultDemandNumberByUser || v.totalNetShippingDemand) })
          })
          break
      }
      this.$refs.OrderTable.updateFooter()
    },
    handleToReview(type) {
      this.currentStatus(type)
    },
    currentStatus(type) {
      if (type === 'next') {
        if (this.active === 1) {
          const { color, style } = this.Deliveryform
          if (color || style) {
            this.$confirm('此操作将重置搜索结果, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(async() => {
                this.resetClick()
                await new Promise((resolve) => {
                  setTimeout(() => {
                    resolve()
                  }, 0)
                })
                this.toPlan()
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消'
                })
              })
          } else {
            this.toPlan()
          }
        } else if (this.active === 0) {
          const { style, color, size, sku } = this.Orderform
          if (style || color || size || sku) {
            this.$confirm('此操作将重置搜索结果, 是否继续?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
              .then(async() => {
                this.resetClick()
                await new Promise((resolve) => {
                  setTimeout(() => {
                    resolve()
                  }, 0)
                })
                this.toOrder()
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消'
                })
              })
          } else {
            this.toOrder()
          }
        } else {
          this.toCommit()
        }
      }
      if (type === 'previous') {
        this.active--
      }
    },
    handleCount(currentList) {
      const totalCountMap = currentList.reduce((acc, curr) => {
        if (curr.styleName) {
          acc[curr.styleName] = (acc[curr.styleName] || 0) + curr.demandNumber
        }
        return acc
      }, {})

      const transformedData = currentList.map(item => ({
        ...item,
        totalCount: totalCountMap[item.styleName]
      }))

      return transformedData
    },
    async handleNext() {
      try {
        this.loading = true
        const data = this.OrderTableData.filter(item => item.demandNumber !== 0)
        const arr = data.map(v => { return { stockChannelId: v.stockChannelId, skuName: v.sku } })
        // dsb04/05新增接口校验
        await produceSkuValidate(arr)
        const { datas: currentList } = await produceNext(data)
        this.DeliveryTableData = this.handleCount(currentList)
        this.DeliveryShowTableData = deepClone(this.DeliveryTableData)
        this.active++
      } finally {
        this.loading = false
      }
    },
    async toOrder() {
      const $table = this.$refs.OrderTable
      const errMap = await $table.validate(true).catch(errMap => errMap)

      if (errMap) {
        this.$message.error('请检查必填项')
      } else if (this.OrderTableData.every(v => !Number(v.demandNumber))) {
        this.$message.error('请检查实际下单数量')
      } else {
        // dsb03新增校验
        if (this.OrderTableData.some(v => v.negativeCashFlag === '是' && Number(v.demandNumber) > 0 && v.seasonId === 5)) {
          return this.$message.error('存在负现金流产品不允许翻单')
        }
        if (this.OrderTableData.some(v => v.positionName === 'EOL' && Number(v.demandNumber) > 0)) {
          this.$confirm('存在EOL产品是否翻单', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.handleNext()
          })
        } else if (this.OrderTableData.some(v => v.seasonId !== 5 && v.negativeCashFlag === '是' && Number(v.demandNumber) > 0)) {
          this.$confirm('存在负现金流两季款产品是否翻单', '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.handleNext()
          })
        } else {
          this.handleNext()
        }
      }
    },
    async toPlan() {
      try {
        if (!this.DeliveryTableData.length) return this.$message.warning('无可提交数据')
        this.loading = true
        const params = deepClone(this.DeliveryTableData).flatMap(v =>
          v.voList.map(vv => ({ ...omit(v, 'voList'), ...vv }))
        )

        const { code, datas } = await groupPurchasePlan(params)
        if (code === 0) {
          this.PlanTableData = datas
          this.active++
        }
      } finally {
        this.loading = false
      }
    },

    async toCommit() {
      try {
        this.loading = true
        const { datas } = await generatePurchasePlan({
          dtoList: this.PlanTableData
        })
        this.isShow = true
        this.successData = datas.map(item => Object.assign({ purchasePlanCode: item.purchasePlanCode, id: item.id }))
        this.loading = false
      } finally {
        this.loading = false
      }
    },

    async getList() {
      try {
        this.getLoading = true
        const { flipOrderData: { result }} = this
        const params = JSON.parse(result).map(item => Object.assign(item, { demandNumber: item.totalNetShippingDemand }))
        this.hanlderData(params)
        this.getLoading = false
      } finally {
        this.getLoading = false
      }
    },
    hanlderData(datas) {
      this.OrderTableData = datas.map(item => {
        if (item.fnskuUpc?.length === 1) {
          const FnskuUpc = item.fnskuUpc[0]
          const arr = FnskuUpc.split('/')
          return { ...item, FnskuUpc, fnsku: arr[0], upc: arr[1] }
        } else {
          return item
        }
      })
      this.OrderShowTableData = this.OrderTableData
      const arr = []
      this.OrderTableData.map(item => arr.push(item.sku))
      this.skuNames = arr.join(',')
      this.stockChannelName = this.OrderTableData[0].stockChannelName
      this._StyleChange()
    },

    // 导入
    getimportTableData(data) {
      this.OrderTableData.map(item => {
        data.map(v => {
          if (v.sku === item.sku) {
            return Object.assign(item, { demandNumber: v.orderNumber, defaultDemandNumberByUser: v.orderNumber })
          }
        })
      })
      this.OrderShowTableData.map(item => {
        data.map(v => {
          if (v.sku === item.sku) {
            return Object.assign(item, { demandNumber: v.orderNumber, defaultDemandNumberByUser: v.orderNumber })
          }
        })
      })
      console.log('this.OrderShowTableData: ', this.OrderShowTableData)
      console.log('this.OrdeTableData: ', this.OrderTableData)

      this.$refs.OrderTable.updateFooter()
    },
    handleImport() {
      this.importDialogVisible = true
      trackImportEvent(`${handleBreadTitle(this.$route)}-确认下单建议${this.$route?.query?.activeName === 'first' ? '快船翻单' : '普船翻单'}-导入`)
    },
    // 导出
    async handleExport() {
      try {
        this.exportLoading = true
        this.OrderShowTableData = this.OrderShowTableData.map(item => ({
          ...item,
          notReservedAvailableInventory: item.notReservedAvailableInventory ? item.notReservedAvailableInventory : 0
        }))
        import('@/vendor/Export2Excel').then((excel) => {
          const header = ['备货渠道', 'Style', 'Color', 'Size', 'Sku', '定位', '是否负现金流', '翻单TurnOver（天）', '装箱数', '翻单需求', '实际下单数量']
          const filterVal = ['stockChannelName', 'styleName', 'color', 'size', 'sku', 'positionName', 'negativeCashFlag', 'turnOver', 'cartonNumber', 'totalNetShippingDemand', 'demandNumber']
          const data = this.formatJson(filterVal, this.OrderShowTableData)
          excel.export_json_to_excel({
            header,
            data,
            filename: `批量确认列表`
          })
          setTimeout(() => {
            this.exportLoading = false
          }, 1000)
          trackExportEvent(`${handleBreadTitle(this.$route)}-${this.$route?.query?.activeName === 'first' ? '快船翻单' : '普船翻单'}-确认下单建议-导出`)
        })
      } catch (err) {
        this.exportLoading = false
      } finally {
        this.exportLoading = false
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, i) => filterVal.map(j => {
        if (j === 'no') {
          return i + 1
        } else {
          return v[j]
        }
      }))
    },
    blurEvents(e, row, key) {
      if (e.target.value === '') {
        row[key] = 0
      }
    },

    sumNum(list, field) {
      let count = 0
      list.forEach(item => {
        // 假如为空的话，则count为原值
        // count = Number(item[field]) ? (Number(item[field]) + count) : count
        count += Number(item[field]) || 0
      })
      return count
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if (['demandNumber'].includes(column.property)) {
            return this.sumNum(data, column.property)
          }
          return null
        })
      ]
    },
    resetClick() {
      this.Orderform = this.$options.data.call(this).Orderform
      this.Deliveryform = this.$options.data.call(this).Deliveryform
      this.queryClick()
    },
    queryClick(flag) {
      // 查询始终是全量查询，并且不改变原数组，只赋值给展示数组
      if (this.active === 0) {
        this.OrderShowTableData = this.handleData(this.OrderTableData, this.Orderform)
        console.log('this.OrderShowTableData: ', this.OrderShowTableData)

        // 触发查询按钮
        if (flag) {
          trackSiteSearch(
            JSON.stringify(handleMonitorParams(this.Orderform)),
            `${handleBreadTitle(this.$route)}-${this.$route?.query?.activeName === 'first' ? '快船翻单' : '普船翻单'}-确认下单建议`,
            false
          )
        }
      } else {
        this.DeliveryShowTableData = this.handleCount(this.handleData(this.DeliveryTableData, this.Deliveryform))
      }
    },
    handleData(data, { style, color, size, sku }) {
      const styleName = this.styleOptions.find(v => v.id === style)?.styleName
      return data.filter(product => (!color || product.color === color) && (!style || product.styleName === styleName) &&
          (!size || product.size === size) &&
          (!sku || product.sku.includes(sku)))
    },
    goBack() {
      // this.$router.go(-1)
      this.$router.push({
        path: '../',
        append: true
      })
    },
    async _StyleChange() {
      this.styleOptions = this.OrderTableData.reduce((acc, current) => {
        if (!acc.some(v => v.id === current.styleId)) {
          acc.push({ id: current.styleId, styleName: current.styleName })
        }
        return acc
      }, [])
    },
    async _queryColorList(styleId) {
      const { datas } = await queryColorList({ styleId })
      this.colorOptions = datas
    },
    async _querySizeList(styleId) {
      const { datas } = await querySizeList({ styleId })
      this.sizeOptions = datas
    }
  }
}
</script>
<style scoped lang="scss">
.upload-dialog /deep/ {
    .el-step__title.is-success, .el-step__head.is-success , .el-step__description.is-success,
    .el-step__title.is-process, .el-step__head.is-process , .el-step__description.is-process{
    color: #1890ff;
    border-color: #1890ff;
  }
}
.upload-order-content {
  width: 90%;
  padding: 50px 0px;
  margin: 0 auto;
  .download-template {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .download-template1 {
    display: flex;
    padding: 30px 10px;
    flex-direction: column;
    align-items: flex-start;
    background: rgb(255, 245, 230);
  }
  .upload-error--item {
    display: flex;
    align-items: center;
  }
  .upload-order {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .el-upload--text {
      order: 2;
    }
    .el-upload__tip {
      order: 1;
    }
    .el-upload-list.el-upload-list--text {
      order: 3;
    }
  }
}
.footer{
  margin-left: 40%;
  margin-right: 40%;
}
.mb-35 {
    margin-bottom: 35px;
  }
  .btn_bottom {
    display: flex;
    justify-content: center;
  }
  .btn_between {
    display: flex;
    justify-content: space-between;
  }
  .main_page {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 300px;
  }
</style>
