
<template>
  <div>
    <el-dialog
      title="导入"
      :visible.sync="visible"
      width="80%"
    >
      <ImportFile
        ref="uploadRef"
        :limit="1"
        class="mt-8px"
        accept=".xlsx, .xls"
        :types="['xls', 'xlsx']"
        :show-file-list="true"
        button-type="text"
        import-name="上传文件"
        @getFile="({file:val})=>file=val"
        @removeFile="removeFile"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="closed">取 消</el-button>
        <el-button type="primary" @click="handleImport">导入预览</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="导入预览"
      :visible.sync="importViewDialogVisible"
      width="80%"
    >
      <el-table
        ref="importTable"
        :data="importTableData"
        :cell-style="cellStyle"
        :row-config="{ height: 80 }"
        max-height="600px"
        :cell-class-name="tableRowName"
        :header-cell-class-name="tableHeaderName"
      >
        <el-table-column prop="errorMessage" label="错误信息" align="center" />
        <el-table-column prop="stockChannelName" label="备货渠道" align="center" />
        <el-table-column prop="styleName" label="Style" align="center" />
        <el-table-column prop="color" label="Color" align="center" />
        <el-table-column prop="size" label="Size" align="center" />
        <el-table-column prop="sku" label="Sku" align="center" />
        <el-table-column prop="orderNumber" label="实际下单数量" align="center" />
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="importViewDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="handleImSubmit">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ImportFile from '@/components/ImportFile'
import { preImportProduce } from '@/api/scm-api'
export default {
  components: { ImportFile },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    stockChannelName: {
      type: String,
      default: ''
    },
    skuNames: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      importViewDialogVisible: false,
      importTable: [],
      importTableData: []
    }
  },
  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  watch: {
    'visible'(val) {
      if (!val) {
        this.file = ''
        this.$refs.uploadRef.$refs.uploadForm.clearFiles()
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    tableRowName({ columnIndex }) {
      if (columnIndex === 6) {
        return 'weightCell'
      }
      return ''
    },
    tableHeaderName(row) {
      if (row.columnIndex === 6) {
        return 'weightCell'
      }
    },
    closed() {
      this.file = ''
      this.active = 0
      this.$emit('input', false)
    },
    async handleImport() {
      try {
        if (!this.file) {
          this.$message.warning('请上传文件')
          return
        } else {
          this.loading = true
          this.$emit('input', false)
          // 调用预览接口
          const formData = new FormData()
          formData.append('file', this.file)
          formData.append('skuNames', this.skuNames)
          formData.append('stockChannelName', this.stockChannelName)
          const { code, datas } = await preImportProduce(formData)
          if (code === 0) {
            this.importViewDialogVisible = true
            this.importDialogVisible = false
            this.importTableData = datas
          }
        }
      } finally {
        // this.$refs.upload.$refs.uploadForm.clearFiles()
        this.file = ''
      }
    },
    handleImSubmit() {
      if (this.importTableData.some((item) => item.errorMessage)) {
        return this.$message.warning('存在错误信息，无法提交')
      } else {
        this.importViewDialogVisible = false
        this.$emit('importTableData', this.importTableData)
      }
      // this.importViewDialogVisible = false
      // this.$emit('importTableData', this.importTableData)
    },
    removeFile(val) {
      this.file = val
    },
    cellStyle({ row }) {
      if (row.errorMessage) {
        return {
          color: 'red'
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
/deep/ .el-table .weightCell {
  color:black;
  font-size:16px;
  font-weight: 600
}
</style>
